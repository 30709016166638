import React, { useEffect, useState } from 'react';
import { useAuth } from '../components/context/authContext';
import { supabase } from '../components/components/supabaseClient';

const OrderHistory = () => {
  const { user } = useAuth();
  const [orders, setOrders] = useState([]);
  
  // Función para obtener los pedidos del usuario
  const fetchOrders = async () => {
    try {
      const { data, error } = await supabase
        .from('orders')  // Nombre de la tabla de pedidos
        .select('*')      // Selecciona todos los campos necesarios
        .eq('user_id', user.id)  // Filtra los pedidos por el ID del usuario
        .order('created_at', { ascending: false });  // Ordenar por fecha de creación, más recientes primero
  
      if (error) {
        console.error('Error al obtener los pedidos:', error);
        return;
      }
  
      setOrders(data);  // Guardar los pedidos en el estado
    } catch (error) {
      console.error('Error general al obtener los pedidos:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchOrders();  // Llamar a la función cuando el componente se monta
    }
  }, [user]);

  if (!user) {
    return <h2>Necesitas iniciar sesión para ver tus pedidos</h2>;
  }
  console.log(orders)
  return (
    <section className='mb-75'>
      <div className='container mtb-20 card-white'>
        <h2>Historial de Pedidos</h2>
        {orders.length === 0 ? (
          <p>No has realizado ningún pedido aún.</p>
        ) : (
          <ul>
            {orders.map(order => (
              <li key={order.id} className='order-wrapper'>
                <h3>Pedido #{order.folio}</h3>
                <div className="order-items">
                  {order.items.map((item, index) => (
                    <div key={index} className="order-item">
                      {/* <img 
                        src={item.image_url} 
                        alt={item.name} 
                        style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
                      /> */}
                      <div className='item-wrapper'>
                        <h4>{item.name}</h4>
                        <p>Cantidad: {item.quantity}</p>
                        <p>Precio: ${item.price}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <p>Total: ${order.total}</p>
                <p className='mb-15'>
                  Fecha: {new Date(order.created_at).toLocaleString('es-MX', {
                    weekday: 'long',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                  })}
                </p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default OrderHistory;