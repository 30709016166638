import React, { useEffect, useState } from 'react';
import { supabase } from '../components/supabaseClient';
import { Table, Button, Modal, Form, Input, Select, message } from 'antd';

const { Option } = Select;

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);

  // Para controlar el modal de agregar/editar
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  // Para saber si estamos editando o creando
  const [editingEmployee, setEditingEmployee] = useState(null);

  // 1) Cargar la lista de empleados
  const fetchEmployees = async () => {
    setLoading(true);
    const { data, error } = await supabase.from('employees').select('*');
    setLoading(false);

    if (error) {
      message.error('Error al obtener la lista de empleados');
      console.error(error);
      return;
    }
    setEmployees(data);
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  // 2) Mostrar modal (nuevo o edición)
  const openModal = (employee = null) => {
    setEditingEmployee(employee);
    if (employee) {
      // Si es edición, rellenamos el formulario con los datos
      form.setFieldsValue({
        first_name: employee.first_name,
        last_name: employee.last_name,
        phone: employee.phone,
        password: employee.password,
        permisos: employee.permisos,
      });
    } else {
      // Si es nuevo, limpiamos el formulario
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  // 3) Guardar (crear o actualizar) un empleado
  const handleSave = async (values) => {
    try {
      // Si existe editingEmployee, estamos editando
      if (editingEmployee) {
        const { error } = await supabase
          .from('employees')
          .update({
            first_name: values.first_name,
            last_name: values.last_name,
            phone: values.phone,
            password: values.password, // Nota: se recomienda encriptar contraseñas
            permisos: values.permisos,
          })
          .eq('id', editingEmployee.id);

        if (error) {
          throw error;
        }
        message.success('Empleado actualizado correctamente');
      } else {
        // Crear nuevo empleado
        const { error } = await supabase
          .from('employees')
          .insert([
            {
              first_name: values.first_name,
              password: values.password, // Nota: se recomienda encriptar contraseñas
              permisos: values.permisos || 'emplyee', // Valor por defecto
            },
          ]);
        if (error) {
          throw error;
        }
        message.success('Empleado agregado correctamente');
      }
      setIsModalVisible(false);
      fetchEmployees();
    } catch (err) {
      console.error(err);
      message.error('Ocurrió un error al guardar el empleado');
    }
  };

  // 4) Eliminar empleado
  const handleDelete = async (employeeId) => {
    try {
      const { error } = await supabase
        .from('employees')
        .delete()
        .eq('id', employeeId);

      if (error) {
        throw error;
      }
      message.success('Empleado eliminado correctamente');
      fetchEmployees();
    } catch (err) {
      console.error(err);
      message.error('Ocurrió un error al eliminar el empleado');
    }
  };

  // Columnas para la tabla
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (text, record) => `${record.first_name}`,
    },
    {
      title: 'Permisos',
      dataIndex: 'permisos',
      key: 'permisos',
      render: (perm) => (perm === 'admin' ? 'Administrador' : 'Empleado'),
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '8px' }}>
          <Button onClick={() => openModal(record)}>Editar</Button>
          <Button danger onClick={() => handleDelete(record.id)}>
            Eliminar
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <h1>Administrar Empleados</h1>
      <Button type="primary" onClick={() => openModal(null)} style={{ marginBottom: 16 }}>
        Agregar Empleado
      </Button>

      <Table
        dataSource={employees}
        columns={columns}
        rowKey="id"
        loading={loading}
      />

      <Modal
        title={editingEmployee ? 'Editar Empleado' : 'Nuevo Empleado'}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => form.submit()}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSave}
        >
          <Form.Item
            label="Nombre"
            name="first_name"
            rules={[{ required: true, message: 'Ingresa el nombre' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contraseña"
            name="password"
            rules={[{ required: true, message: 'Ingresa la contraseña' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Permisos"
            name="permisos"
            initialValue="emplyee"
            >
            <Select>
                <Option value="admin">Administrador</Option>
                <Option value="emplyee">Empleado</Option>
            </Select>
            </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EmployeeManagement;