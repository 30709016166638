// src/utils/formatDate.js
import dayjs from 'dayjs'; // Puedes usar dayjs, que es más ligero

/**
 * Formatea una fecha según el formato dado (por defecto DD/MM/YYYY)
 * @param {Date | string | number} date - La fecha a formatear.
 * @param {string} formatStr - El formato deseado.
 * @returns {string} La fecha formateada.
 */
export function formatDate(date, formatStr = 'DD/MM/YYYY') {
    return dayjs(date).format(formatStr);
}