import React, { useState, useEffect } from 'react';
import { Button, Modal, DatePicker } from 'antd'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import DropdownForeign from './DropdownForeign';
import DropDownPickUp from './DropDownPickUp';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
// import 'moment/locale/es'; 

const ShippingMethod = ({ onNext }) => {
  const location = useLocation();
  const cartTotal = location.state?.cartTotal || 0;

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(null);

  const navigate = useNavigate();
  const { user } = useAuth();
  const [name, setName] = useState(user?.user_metadata?.full_name || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    if (!selectedMethod) return false;
  
    const safeName = name?.toString().trim() || '';
    const safePhone = phone?.toString().trim() || '';
    const safeAddress = selectedAddress?.toString().trim() || '';
    const safeHour = selectedHour?.toString().trim() || '';
  
    switch (selectedMethod) {
      case 'store-pickup':
        return safeName !== '';
  
      case 'foreign-shipping':
        return safeName !== '' && safePhone !== '' && safeAddress !== '';
  
      case 'pickup-point':
        return safeName !== '' && safePhone !== '' && safeAddress !== '';
  
      case 'package-shipping':
        // return true;
        return safeName !== '' && selectedAddress !== '' && deliveryDate !== null;
  
      case 'national-shipping':
        return safeName !== '' && safePhone !== '' && safeAddress !== '';
  
      default:
        return false;
    }
  };

  useEffect(() => {
    const valid = validateForm();
    console.log('Validando formulario:', {
      selectedMethod,
      name,
      phone,
      selectedAddress,
      deliveryDate: deliveryDate ? deliveryDate.format('YYYY-MM-DD HH:mm:ss') : deliveryDate,
      selectedHour,
      valid
    });
    setIsFormValid(valid);
  }, [selectedMethod, name, phone, selectedAddress, deliveryDate, selectedHour]);
  

  // =========================================================
  //                   FUNCIONES AUXILIARES
  // =========================================================

  /**
   * 1) Envío Foráneo
   *    - Actopan => se entrega el próximo jueves.
   *    - Tulancingo o Cd. Sahagún => se entrega el próximo viernes.
   *    - Si estamos después de las 5:30 p.m. del día anterior (miércoles para jueves, jueves para viernes),
   *      se recorre a la siguiente semana.
   */
  const calculateForeignShippingDate = (address) => {
    if (!address) return null;

    const now = dayjs();
    let shippingDay; // 4 = jueves, 5 = viernes

    if (address.includes('Actopan') || address.includes('Cd. Sahagún') ) {
      shippingDay = 4; // Jueves
    } else if (address.includes('Tulancingo')) {
      shippingDay = 5; // Viernes
    } else {
      // Si no coincide con esas zonas, puedes retornar null o manejar otra lógica
      return null;
    }

    // Buscamos el siguiente "shippingDay" a partir de "mañana"
    let date = now.clone().add(1, 'day');
    while (date.day() !== shippingDay) {
      date = date.add(1, 'day');
    }

    // Ahora, definimos el "día anterior a la entrega" a las 17:30
    // Ej: si la entrega es jueves (4), el día anterior es miércoles (3) a las 17:30
    //     si la entrega es viernes (5), el día anterior es jueves (4) a las 17:30
    const dayBefore = date.clone()
      .subtract(1, 'day')
      .set({ hour: 17, minute: 30, second: 0, millisecond: 0 });

    // Si ya estamos después de ese "dayBefore", saltamos a la siguiente semana
    if (now.isAfter(dayBefore)) {
      date = date.add(7, 'days'); // siguiente jueves o viernes
    }

    return date;
  };

  /**
   * 2) Punto Fijo (pickup-point):
   *    - Siempre martes 12pm.
   *    - Si hoy es lunes antes de 5:30 pm, se entrega mañana (martes de esta semana).
   *    - Caso contrario, se recorre al martes de la próxima semana.
   */
  const calculatePickupPointDate = () => {
    const now = dayjs();
    const mondayCutoff = dayjs()
      .day(1)
      .hour(17)
      .minute(30)
      .second(0)
      .millisecond(0);

    const isMondayBeforeCutoff = now.day() === 1 && now.isBefore(mondayCutoff);

    if (isMondayBeforeCutoff) {
      // Martes de esta misma semana, 12:00
      return now.clone().day(2).hour(12).minute(0).second(0).millisecond(0);
    } else {
      // Martes de la próxima semana
      return now.clone().day(9).hour(12).minute(0).second(0).millisecond(0);
    }
  };

  /**
   * 3) Store Pickup: Próximo día hábil (evitando domingo).
   *    - Si quieres evitar sábado también, ajusta la condición.
   */
  const getNextBusinessDay = () => {
    const today = dayjs();
    let nextBusinessDay = today.clone().add(1, 'days');
    while (nextBusinessDay.day() === 0) {
      // Si deseas saltar sábado: while (nextBusinessDay.day() === 0 || nextBusinessDay.day() === 6)
      nextBusinessDay = nextBusinessDay.add(1, 'days');
    }
    return nextBusinessDay;
  };

  /**
   * 4) Package-Shipping:
   *    - Solo 3 días hábiles disponibles.
   *    - Excluye viernes(5), sábado(6) y domingo(0).
   *    - Si ya pasaron las 5:30 pm de hoy, omitimos el "día siguiente" inmediato.
   */
  const disabledDateForPackageShipping = (current) => {
    // a) No permitir fechas pasadas
    if (!current || current < dayjs().startOf('day')) {
      return true;
    }

    // b) Excluir viernes (5), sábado (6) y domingo (0)
    if ([0, 5, 6].includes(current.day())) {
      return true;
    }

    // c) Revisar si ya pasamos las 5:30 pm de HOY
    const now = dayjs();
    const deadlineToday = dayjs().set({ hour: 17, minute: 30, second: 0, millisecond: 0 });
    const isAfterDeadline = now.isAfter(deadlineToday);

    // d) Construir una lista con los próximos 3 días hábiles
    const validDates = [];
    const offset = isAfterDeadline ? 2 : 1; // si es afterDeadline, empezamos desde "pasado mañana"
    let checkDay = now.clone().add(offset, 'day').startOf('day');

    while (validDates.length < 3) {
      if (![0, 5, 6].includes(checkDay.day())) {
        validDates.push(checkDay.clone());
      }
      checkDay = checkDay.add(1, 'day');
    }

    console.log('disabledDateForPackageShipping - current:', current.format('YYYY-MM-DD'), ', validDates:', validDates.map(d => d.format('YYYY-MM-DD')));

    // e) Deshabilitar si no está en la lista de válidos
    return !validDates.some((validDay) => current.isSame(validDay, 'day'));
  };

  // =========================================================
  //                    EFECTOS PRINCIPALES
  // =========================================================

  // Cuando cambia la dirección en foreign-shipping, recalculamos
  useEffect(() => {
    if (selectedMethod === 'foreign-shipping' && selectedAddress) {
      const date = calculateForeignShippingDate(selectedAddress);
      setDeliveryDate(date);
    }
  }, [selectedMethod, selectedAddress]);

  // Cuando se selecciona pickup-point, se calcula la fecha automáticamente
  useEffect(() => {
    if (selectedMethod === 'pickup-point') {
      const pickupDate = calculatePickupPointDate();
      setDeliveryDate(pickupDate);
    }
  }, [selectedMethod]);

  // Cuando se selecciona store-pickup, buscamos siguiente día hábil
  useEffect(() => {
    if (selectedMethod === 'store-pickup') {
      const nextDay = getNextBusinessDay();
      setDeliveryDate(nextDay);
    }
  }, [selectedMethod]);

  // =========================================================
  //                      HANDLERS
  // =========================================================

  const handleSelectMethod = (method) => {
    // Validación de monto mínimo para envío a domicilio
    if (method === 'package-shipping' && Number(cartTotal) < 200) {
      Modal.warning({
        title: 'Monto mínimo requerido',
        content:
          'El monto mínimo para el envío a domicilio es de $200. Agrega más productos para habilitar esta opción.',
      });
      return;
    }

    setSelectedMethod(method);

    // Si se cambia a "pickup-point" o "store-pickup", la fecha se setea en los useEffect.
    // Para limpiar la fecha si cambiamos de método:
    if (method !== 'pickup-point' && method !== 'store-pickup' && method !== 'foreign-shipping' && method !== 'package-shipping') {
      setDeliveryDate(null);
    }
  };

  // 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedMethod) {
      Modal.error({
        title: 'Error',
        content: 'Debes seleccionar un método de envío.',
      });
      return;
    }
  
    let deliveryDateToSave = null;
    if (deliveryDate) {
      deliveryDateToSave = deliveryDate.toISOString();
    }
  
    const details = {
      method: selectedMethod || '',
      name: name || '',
      phone: phone || '',
      store: selectedStore || '',
      address: selectedAddress || '',
      deliveryDate: deliveryDateToSave || '',
      hour: selectedHour || '',
    };
  
    console.log('Detalles enviados a OrderSummary:', details);
  
    // Asegurar que no haya valores null o undefined en el state para evitar errores
    navigate('/order-summary', { state: { details } });
  };
  
  // =========================================================
  //                     RENDER DEL COMPONENTE
  // =========================================================

  return (
    <section>
      <div className="container mb-75">
        <div className="shipping-method mtb-20">
          <button className="btn btn-yellow mb-16" onClick={() => navigate(-1)}>
            Regresar
          </button>
          <h2 className="mb-16">Elige tu forma de entrega</h2>

          {/* ================== STORE PICKUP ================== */}
          <div
            className={`shipping-option ${selectedMethod === 'store-pickup' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('store-pickup')}
          >
            <div className="first-wrapper">
              <span>Recoger en tienda</span>
              <div className="method_price">Gratis</div>
            </div>
            {selectedMethod === 'store-pickup' && (
              <form className="store-pickup">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  {deliveryDate && deliveryDate.isValid() && (
                    <p className="mtb-16">
                      Fecha de recogida en tienda:
                      <br />
                      <strong>{deliveryDate.format('dddd DD [de] MMMM')}</strong>
                    </p>
                  )}
                </div>
              </form>
            )}
          </div>

          {/* ================= FOREIGN SHIPPING ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'foreign-shipping' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('foreign-shipping')}
          >
            <div className="first-wrapper">
              <span>Envío foráneo</span>
              <div className="method_price">Gratis</div>
            </div>
            {selectedMethod === 'foreign-shipping' && (
              <form className="foreign-shipping">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <p className="mb-16">Zona de entrega:</p>
                  <DropdownForeign onSelect={(addr) => setSelectedAddress(addr)} />

                  {deliveryDate && (
                    <div className="mtb-16">
                      <p>
                        <strong>Fecha de entrega estimada:</strong>
                      </p>
                      <p>
                        {deliveryDate.format('dddd DD [de] MMMM')}
                        <br />
                        (Recuerda que si haces el pedido después de las 5:30 pm
                        del día anterior de reparto, se recorre a la siguiente semana.)
                      </p>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>

          {/* ================= PICKUP POINT ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'pickup-point' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('pickup-point')}
          >
            <div className="first-wrapper">
              <span>Punto de entrega (solo martes a las 12pm)</span>
              <div className="method_price">Gratis</div>
            </div>
            {selectedMethod === 'pickup-point' && (
              <form className="pickup-point">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <p className="mtb-16">Selecciona un punto de entrega:</p>
                  <DropDownPickUp onSelect={(addr) => setSelectedAddress(addr)} />

                  {deliveryDate && (
                    <p className="mtb-16">
                      Entrega programada:
                      <br />
                      <strong>
                        {deliveryDate.format('dddd DD [de] MMMM [a las] HH:mm [hrs]')}
                      </strong>
                    </p>
                  )}
                  <small className="font-size-xs">
                    Si tu pedido se realiza el lunes antes de las 5:30 pm, se entrega
                    el martes de esta semana. De lo contrario, el martes de la siguiente.
                  </small>
                </div>
              </form>
            )}
          </div>

          {/* ================= PACKAGE SHIPPING ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'package-shipping' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('package-shipping')}
          >
            <div className="first-wrapper mb-10">
              <span>Envío a domicilio</span>
              <div className="method_price">$50.00</div>
            </div>
            <p className="mb-5">
              Disponible para Pachuca y Mineral de la Reforma. 
              Monto mínimo: $200.
            </p>
            {selectedMethod === 'package-shipping' && (
              <form className="package-shipping">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <label className="mtb-16">Dirección de envío:</label>
                  <input
                    type="text"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    placeholder="Ingresa la dirección de envío"
                    required
                  />

                  <p>Selecciona tu fecha de entrega (solo 3 días hábiles próximos):</p>
                  <DatePicker
                    format="YYYY-MM-DD"
                    disabledDate={disabledDateForPackageShipping}
                    onChange={(date) => {
                      console.log('Fecha seleccionada:', date);
                      setDeliveryDate(date ? dayjs(date) : null);
                    }}
                  />

                  <p className="mtb-8">
                    Horario de reparto: 10:00 a.m. a 03:00 p.m.
                    <br />
                    *Si haces pedido después de las 5:30 pm de hoy, 
                    mañana no estará disponible (salta al siguiente día hábil).
                  </p>

                  <label className="mtb-16">Agenda tu entrega (franja horaria):</label>
                  <input
                    type="text"
                    value={selectedHour}
                    onChange={(e) => setSelectedHour(e.target.value)}
                    placeholder="Ejemplo: 11:00 a 01:00"
                    required
                  />
                </div>
              </form>
            )}
          </div>

          {/* ================= NATIONAL SHIPPING ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'national-shipping' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('national-shipping')}
          >
            <div className="first-wrapper mb-10">
              <span>Paquetería</span>
              <div className="method_price">$350.00</div>
            </div>
            <p className="mb-5">Envío a toda la república</p>
            {selectedMethod === 'national-shipping' && (
              <form className="national-shipping">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <label className="mtb-16">Dirección de envío:</label>
                  <input
                    type="text"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    placeholder="Ingresa la dirección de envío"
                    required
                  />
                  <p className="font-size-xs">
                    Medidas máximo 50x50x50 (25kg). Si excede, cotizamos envío vía WhatsApp.
                  </p>
                </div>
              </form>
            )}
          </div>

          <button className="confirm-button" onClick={handleSubmit} disabled={!isFormValid}>
            Confirmar método de envío
          </button>
        </div>
      </div>
    </section>
  );
};

export default ShippingMethod;