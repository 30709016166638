import React, { useEffect, useState } from 'react';
import { supabase } from '../components/supabaseClient';
import dayjs from 'dayjs';
import { Table } from 'antd';

const movementTypeTranslations = {
  sale: 'Venta',
  update: 'Actualización'
  // Agrega más traducciones según sea necesario
};

const StockHistory = () => {
  const [movements, setMovements] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchMovements = async () => {
    setLoading(true);
    const { data, error } = await supabase
        .from('stock_movements')
        .select(`*, product:products(name)`) 
        .order('movement_date', { ascending: false });
    if (error) {
      console.error('Error al obtener los movimientos:', error);
    } else {
      setMovements(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchMovements();
  }, []);

  return (
    <div className="stock-history">
      <h2>Historial de Movimientos de Stock</h2>
      <Table 
        dataSource={movements} 
        loading={loading} 
        rowKey="id"
        columns={[
          { title: 'ID', dataIndex: 'id', key: 'id' },
          { 
            title: 'Producto', 
            dataIndex: 'product', 
            key: 'product', 
            render: (text, record) => record.product ? record.product.name : record.product_id 
          },
          { 
            title: 'Tipo', 
            dataIndex: 'movement_type', 
            key: 'movement_type',
            render: (text) => movementTypeTranslations[text] || text
          },
          { title: 'Cantidad', dataIndex: 'quantity', key: 'quantity' },
          { title: 'Stock Anterior', dataIndex: 'previous_stock', key: 'previous_stock' },
          { title: 'Stock Nuevo', dataIndex: 'new_stock', key: 'new_stock' },
          { 
            title: 'Fecha', 
            dataIndex: 'movement_date', 
            key: 'movement_date',
            render: (text) => dayjs(text).format('DD/MM/YYYY HH:mm') 
          },
          { title: 'Folio del Pedido', dataIndex: 'order_folio', key: 'order_folio', render: (text) => text || 'Interno' }
        ]}
      />
    </div>
  );
};

export default StockHistory;