// import React, { useState } from 'react';
// import { Select, message } from 'antd';
// import { supabase } from '../components/supabaseClient';

// const { Option } = Select;

// const OrderSearchSelect = ({ onSelectOrder }) => {
//     const [options, setOptions] = useState([]);

//     const handleSearch = async (value) => {
//         const searchTerm = value.trim();
//         if (!searchTerm) {
//         setOptions([]);
//         return;
//         }
//         try {
//             const { data, error } = await supabase
//             .from('orders')
//             .select('folio, guest, guest_phone, profiles(full_name)')
//             .or(
//               `folio.ilike.%${searchTerm}%,guest.ilike.%${searchTerm}%,guest_phone.ilike.%${searchTerm}%`
//             );
//         if (error) {
//             message.error('Error al buscar órdenes');
//             return;
//         }
//         if (data) {
//             // Generamos las opciones: el valor es el folio y la etiqueta muestra información adicional
//             const opts = data.map((order) => ({
//                 value: order.folio,
//                 label: `${order.folio} - ${order.profiles ? order.profiles.full_name : order.guest || 'Nombre no disponible'} (${order.guest_phone || 'Sin teléfono'})`
//             }));
//             setOptions(opts);
//         }
//         } catch (err) {
//         console.error(err);
//         message.error('Error inesperado al buscar órdenes');
//         }
//     };

//     return (
//         <div className="search-input">
//         <Select
//         showSearch
//         placeholder="Buscar órdenes por folio, nombre o teléfono"
//         onSearch={handleSearch}
//         options={options}
//         onSelect={onSelectOrder}
//         style={{ width: 300 }}
//         filterOption={false} // Deshabilitamos el filtrado local porque la búsqueda es remota
//         />
//         </div>

//     );
// };

// export default OrderSearchSelect;

import React, { useState } from 'react';
import { Select, message } from 'antd';
import { supabase } from '../components/supabaseClient';

const { Option } = Select;

const OrderSearchSelect = ({ onSelectOrder }) => {
    const [options, setOptions] = useState([]);

    const handleSearch = async (value) => {
        const searchTerm = value.trim();
        if (!searchTerm) {
            setOptions([]);
            return;
        }
        try {
            const { data, error } = await supabase
                .from('orders')
                .select('folio, guest, guest_phone, profiles!left(full_name)') // Evitar múltiples coincidencias
                .or(`folio.ilike.%${searchTerm}%,guest.ilike.%${searchTerm}%,guest_phone.ilike.%${searchTerm}%`)
                .order('created_at', { ascending: false });
            if (error) {
                console.error("Error en la consulta de órdenes:", error);
                message.error('Error al buscar órdenes');
                return;
            }
    
            if (data && data.length > 0) {
                // 🔹 Eliminar pedidos duplicados antes de actualizar el estado
                const pedidosUnicos = Array.from(new Map(data.map(order => [order.folio, order])).values());
    
                const opts = pedidosUnicos.map((order) => ({
                    value: order.folio,
                    label: `${order.folio} - ${
                        order.profiles?.full_name || order.guest || 'Nombre no disponible'
                    } (${order.guest_phone || 'Sin teléfono'})`
                }));
    
                setOptions(opts);
            } else {
                setOptions([]);
            }
        } catch (err) {
            console.error("Error inesperado al buscar órdenes:", err);
            message.error('Error inesperado al buscar órdenes');
        }
    };

    return (
        <div className="search-input">
            <Select
                showSearch
                placeholder="Buscar órdenes por folio, nombre o teléfono"
                onSearch={handleSearch}
                options={options}
                onSelect={onSelectOrder}
                style={{ width: 300 }}
                filterOption={false} // La búsqueda es remota
                notFoundContent="No se encontraron resultados"
            />
        </div>
    );
};

export default OrderSearchSelect;