
import { jsPDF } from 'jspdf';
import dayjs from 'dayjs';

export const printTicket = (order) => {
  console.log("Datos de la orden para imprimir:", order);

  const safeNumber = (n) => (typeof n === 'number' ? n.toFixed(2) : '0.00');

  // 🔹 Determinar altura dinámica del ticket
  const itemHeight = order.items.length * 4; // Ajusta el espacio según cantidad de ítems
  const ticketHeight = 80 + itemHeight; // Base 80mm + espacio dinámico

  // 🔹 Crear instancia de jsPDF (60mm de ancho, altura variable)
  const doc = new jsPDF({
    orientation: 'p',
    unit: 'mm',
    format: [60, ticketHeight]
  });

  // 🔹 Encabezado
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text("MRT Comercializadora", 5, 8);

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text("SIEMBRA 193, Pachuca, Mexico", 5, 13);
  doc.text("Tel: 771 129 1621", 5, 17);

  doc.setLineWidth(0.2);
  doc.line(5, 19, 55, 19);

  // 🔹 Datos de la orden
  doc.setFontSize(10);
  doc.setFont('helvetica', 'bold');
  doc.text(`Folio: ${order.folio}`, 5, 24);
  const fecha = order.created_at ? dayjs(order.created_at).format('DD/MM/YYYY HH:mm') : '';
  doc.text(`Fecha: ${fecha}`, 5, 28);

  // 🔹 Cliente
  const cliente =
    order.profiles && order.profiles.full_name && order.profiles.full_name.trim() !== ''
      ? order.profiles.full_name
      : (order.guest && order.guest.trim() !== '' ? order.guest : 'Nombre no disponible');

  doc.text(`Cliente: ${cliente}`, 5, 32);
  doc.setFont('helvetica', 'normal');
  doc.text(`Tel: ${order.guest_phone || 'Sin teléfono'}`, 5, 36);

  // 🔹 Listado de ítems
  let y = 42;
  doc.setFont('helvetica', 'bold');
  doc.text("Producto", 5, y);
  doc.text("Cant.", 30, y);
  doc.text("Total", 45, y);
  y += 3;
  doc.line(5, y, 55, y); // Línea divisoria
  y += 3;

  doc.setFont('helvetica', 'normal');
  order.items.forEach((item) => {
    doc.text(`${item.name.substring(0, 14)}`, 5, y); // 🔹 Limita el nombre a 14 caracteres
    doc.text(`${item.quantity}`, 32, y, { align: 'right' });
    doc.text(`$${(item.price * item.quantity).toFixed(2)}`, 55, y, { align: 'right' });
    y += 4;
  });

  // 🔹 Totales
  doc.line(5, y, 55, y); // Línea divisoria
  y += 3;
  doc.setFont('helvetica', 'bold');
  doc.text(`Total: $${order.total.toFixed(2)}`, 5, y);

  // 🔹 Pie de página
  y += 8;
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text("¡Gracias por su compra!", 5, y);

  // 🔹 AutoPrint y abrir en nueva ventana
  doc.autoPrint();
  doc.output('dataurlnewwindow');
};